import firebase from "firebase/compat";
export const firebaseConfig = {
  apiKey: "AIzaSyCJjA7JH6HsqEKTQjSxaMluzvMWqEk5Vo0",
  authDomain: "visma-admin.firebaseapp.com",
  projectId: "visma-admin",
  storageBucket: "visma-admin.appspot.com",
  messagingSenderId: "281395664465",
  appId: "1:281395664465:web:7fcbfe8f937fcf317f8a70",
  measurementId: "G-39PBJDF94F",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const auth = firebase.auth();
export const db = firebase.firestore();
